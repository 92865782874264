_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Harap berikan nilai yang cocok untuk {0}",
	"crm.label.field":"Bidang",//no i18n
"crm.label.value":"Nilai",//no i18n
	"sentiment.criteria.wrongcriteria":"Nilai kriteria tidak boleh lebih dari {0}",//no i18n
"crm.mb.field.common.splc":"Karakter khusus tidak diizinkan. Masukkan nilai yang valid.",//no i18n
	"crm.label.field.plural":"bidang",//no i18n
	"crm.label.in.minutes":"{0} (dalam menit)",//no i18n
	"crm.security.roles.list":"Daftar Peran",//no i18n
"crm.security.roles.lookup.info":"Pilih satu peran dari daftar.",//no i18n
"crm.territory.addterritory":"Tambahkan Wilayah",//no i18n
"crm.title.edit.territory":"Edit Wilayah",//no i18n
"crm.territory.title.assign.territories":"Tetapkan Wilayah",//no i18n
	"crm.label.context.help":"Bantuan",//no i18n
"crm.label.from":"Dari",//no i18n
"crm.label.to":"Kepada",//no i18n
"workflow.option.webhookFailure.fromDate":"Mulai Tanggal",//no i18n
"workflow.option.webhookFailure.toDate":"Hingga Tanggal",//no i18n
"crm.custom.field.less.than.equalto":"{0} harus kurang dari atau sama dengan {1}.",//no i18n
"crm.template.listview.search.no.results":"Tidak ada hasil yang ditemukan",//no i18n
"crm.wf.usage.date.criteria.error.msg":"Tanggal dari tidak boleh lebih dari tanggal hingga.",//no i18n
"crm.label.tag.new":"Tag Baru",//no i18n
"crm.label.enter.tag":"Masukkan Tag",//no i18n
"Administrator":"Administrator",//no i18n
"Standard":"Standar",//no i18n
"crux.comboBox.max.limit":"Anda tidak bisa memilih lebih dari {0} {1}.",//no i18n
"crm.button.add":"Tambahkan",//no i18n
"crm.label.users":"Pengguna",//no i18n
"crm.workflow.alert.roles":"Peran",//no i18n
"crm.security.groups":"Grup",//no i18n
"crm.label.available":"Tersedia",//no i18n
"crm.label.assign.manually":"Tetapkan",//no i18n
"crm.globalsearch.option.all":"Semua",//no i18n
"webform.status.Active":"Aktif",//no i18n
"Inactive":"Tidak aktif",//no i18n
"Confirmed":"Dikonfirmasi",//no i18n
"DeletedUser":"Dihapus",//no i18n
"crm.user.component.unconfirmed":"Tidak dikonfirmasi",//no i18n
"crm.feed.group.admin":"Admin",//no i18n
"crm.ln.lable.current":"Saat Ini",//no i18n
"crm.security.group.users.empty":"Tidak ditemukan pengguna.",//no i18n
"crm.label.picklist.none":"Tidak ada",//no i18n
"crm.label.selected":"Dipilih",//no i18n
"crm.label.notSelected":"Tidak Dipilih",//no i18n
"AM":"Pagi",//no i18n
"Call":"Panggil",//no i18n
"crm.phoneNo.Link.Title":"Panggil menggunakan Skype",//no i18n
"crm.button.cancel":"Batalkan",//no i18n
"crm.button.save":"Simpan",//no i18n
"crm.no.data.found":"Tidak ada data yang ditemukan.",//no i18n
"crm.usrpop.non.selected":"Pengguna yang Dipilih",//no i18n
"crm.zti.label.user":"Nama Pengguna",//no i18n
"crm.auditlog.user":"Pengguna",//no i18n
"cob.role":"Peran",//no i18n
"zoho.email":"Email",//no i18n
"Profile":"Profil",//no i18n
"crm.label.no.options.found":"Tidak ada opsi yang ditemukan.",//no i18n
"crm.globalsearch.search.title":"Cari",//no i18n
"None":"Tidak ada",//no i18n
"crm.label.criteria.pattern":"Pola Kriteria",//no i18n
"crm.label.edit.criteria.pattern":"Edit Pola",//no i18n
"criteria.error.alert.brackets.count.mismatch":"Tanda kurung pola tidak cocok.",//no i18n
"criteria.error.alert.brackets.invalid":"Tanda kurung tidak valid di sekitar operator kondisi.",//no i18n
"crm.criteria.number.notmatch.check":"Harap periksa pola di {0}.",//no i18n
"criteria.error.alert.other.params":"Konten tidak valid dalam pola ini.",//no i18n
"crm.label.search.for.users":"Cari Pengguna",//no i18n
"criteria.error.alert.andor.rowcount.mismatch":"Pola Kriteria tidak cocok dengan kondisi yang Anda pilih.",//no i18n
"criteria.error.alert.critnum.rowcount.mismatch":"Pola Kriteria tidak cocok dengan kondisi yang Anda pilih.",//no i18n
"and":"dan",//no i18n
"or":"atau",//no i18n
"crm.label.or":"ATAU",//no i18n
"crm.label.and":"DAN",//no i18n
"crm.criteria.fieldlabel.valid.check":"Harap masukkan label bidang yang valid untuk baris {0}.",//no i18n
"crm.criteria.condition.valid.check":"Harap tentukan kondisi yang valid untuk {0}.",//no i18n
"crm.field.valid.check":"Silakan masukkan {0} yang valid.",//no i18n
"crm.custom.field.less.than.to":"Rentang <i>Dari</i> tidak boleh lebih besar dari rentang <i>Hingga</i>.",//no i18n
"crm.alert.label.savepattern":"Simpan pola sebelum mengubah kriteria.",//no i18n
"crm.criteria.max.rowcnt.exceeds":"Anda tidak dapat menambahkan kriteria tambahan.",//no i18n
"is":"adalah",//no i18n
"isn\'t":"tidak",//no i18n
"contains":"berisi",//no i18n
"doesn\'t contain":"tidak berisi",//no i18n
"starts with":"mulai dengan",//no i18n
"ends with":"berakhir dengan",//no i18n
"is empty":"kosong",//no i18n
"is not empty":"tidak kosong",//no i18n
"is before":"sebelum",//no i18n
"is after":"setelah",//no i18n
"between":"antara",//no i18n
"not between":"tidak di antara",//no i18n
"Today":"Hari ini",//no i18n
"Tommorow":"Besok",//no i18n
"Tommorow Onwards":"Mulai besok",//no i18n
"Yesterday":"Kemarin",//no i18n
"Till Yesterday":"Hingga Kemarin",//no i18n
"Last Month":"Bulan Lalu",//no i18n
"Current Month":"Bulan Sekarang",//no i18n
"Next Month":"Bulan Depan",//no i18n
"Last Week":"Minggu Lalu",//no i18n
"Current Week":"Minggu Ini",//no i18n
"Next Week":"Minggu Depan",//no i18n
"Age in Days":"Usia dalam Hari",//no i18n
"Due in Days":"Tenggat waktu dalam Hari",//no i18n

"Scheduled":"Terjadwal",//no i18n
"Attended Dialled":"Panggilan Dijawab",//no i18n
"Unattended Dialled":"Panggilan Tidak Dijawab",//no i18n
"Overdue":"Jatuh tempo",//no i18n
"Cancelled":"Dibatalkan",//no i18n
"Received":"Diterima",//no i18n
"Missed":"Terlewatkan",//no i18n
"crm.alert.character.not.allowed":"{0} tidak diizinkan",//no i18n
"crm.condition.in.last":"yang terakhir",//no i18n
"crm.zinvoice.dueIn":"jatuh tempo dalam",//no i18n
"on":"Pada",//no i18n
"before":"sebelum",//no i18n
"crm.label.general.small.after":"setelah",//no i18n
"crm.thisweek":"Minggu Ini",//no i18n
"crm.label.this.month":"Bulan Ini",//no i18n
"crm.thisyear":"Tahun Ini",//no i18n
"crm.source.user.and.system":"Pengguna & Sistem",//no i18n
"crm.source.user.or.system":"Pengguna Atau Sistem",//no i18n
"crm.label.system2":"Sistem",//no i18n
"crm.source.user.only":"Hanya oleh Pengguna",//no i18n
"crm.source.system.only":"Hanya oleh Sistem",//no i18n
"crm.condition.till.today":"Hingga Hari Ini",//no i18n
"game.month.left":"1 bulan",//no i18n
"game.months.left":"{0} bulan",//no i18n
"crm.condition.last.30.days":"30 hari terakhir",//no i18n
"crm.condition.last.60.days":"60 hari terakhir",//no i18n
"crm.condition.last.90.days":"90 hari terakhir",//no i18n
"crm.label.filter.typehere":"Ketik di sini",//no i18n
"crm.filter.is.not":"tidak",//no i18n
"crm.condition.until.now":"Hingga Sekarang",//no i18n
"crm.filter.email.isblocked":"diblokir",//no i18n
"crm.filter.email.isnotblocked":"tidak diblokir",//no i18n
"crm.label.no.results.match":"Tidak ada hasil yang cocok",//no i18n
"crm.label.select.user":"Klik untuk Memilih Pengguna.",//no i18n
"current.logged.in.user":"Pengguna yang Masuk",//no i18n
"current.logged.in.user.definition":"Pengguna yang memulai Tindakan Catatan.",//no i18n
"crm.security.group":"Grup",//no i18n
"crm.security.role":"Peran",//no i18n
"Date":"Tanggal",//no i18n
"crm.field.valid.decimal.check2":"Tempat desimal untuk bidang <i>{0}</i> harus kurang dari atau sama dengan {1}.",//no i18n
"crm.field.empty.check":"{0} tidak boleh kosong.",//no i18n

//filter related keys-start
"crm.lead.prediction.convert.high":"Tinggi",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"Menengah",//no i18n
"crm.lead.prediction.convert.low":"Rendah",//no i18n
"crm.lead.prediction.tooltip.convert":"KEMUNGKINAN",//no i18n
"crm.lead.prediction.tooltip.score":"SKOR",//no i18n
"Planned":"Direncanakan",//no i18n
"Invited":"Diundang",//no i18n
"Sent":"Terkirim",//no i18n
"Received":"Diterima",//no i18n
"Opened":"Dibuka",//no i18n
"Responded":"Direspons",//no i18n
"Bounced":"Dipentalkan",//no i18n
"Opted\ Out":"Dikeluarkan",//no i18n
"crm.filter.label.with.open":"Dengan {0} Terbuka",//no i18n
"crm.filter.label.without.open":"Tanpa {0} Terbuka",//no i18n
"crm.filter.label.without.any":"Tanpa Salah Satu {0}",//no i18n
"crm.filter.label.with.module":"Dengan {0}",//no i18n
"Overdue":"Jatuh tempo",//no i18n
"crm.filter.label.activity.due":"{0} Jatuh Tempo",//no i18n
"crm.filter.label.activity.done":"{0} Selesai",//no i18n
"Notes":"Catatan",//no i18n
"crm.filter.label.notes.added":"Catatan Ditambahkan",//no i18n
"crm.label.filter.email.status":"Status Email Terbaru",//no i18n
"crm.label.filter.email.clicked":"diklik",//no i18n
"crm.label.filter.email.responded":"ditanggapi", //no i18n
"crm.label.filter.email.info":"Filter catatan berdasarkan status terbaru email Anda yang terkirim/diterima.",//no i18n
"crm.filter.label.sent":"terkirim",//no i18n
"crm.filter.label.not.sent":"tidak terkirim",//no i18n
"crm.filter.label.opened":"dibuka",//no i18n
"crm.filter.label.not.opened":"tidak dibuka",//no i18n
"crm.filter.label.received":"diterima",//no i18n
"crm.filter.label.not.received":"tidak diterima",//no i18n
"crm.filter.label.bounced":"dipentalkan",//no i18n
"crm.filter.label.opened.not.replied":"dibuka dan tidak ditanggapi", //no i18n
"crm.filter.label.any":"Salah satu di atas",//no i18n
"crm.zia.config.potential.amount":"Jumlah {0}",//no i18n
"Quote\ Stage":"Tahap {0}",//no i18n
"crm.module.owner":"Pemilik {0}",//no i18n
"Potential\ Closing\ Date":"Tanggal Penutupan {0}",//no i18n
"crm.lead.prediction.likely.convert":"Kemungkinan akan dikonversi",//no i18n
"crm.predictions.feature.label":"Prediksi",//no i18n
"crm.intelligence.prediction.likelywin":"Kemungkinan Menang",//no i18n
"crm.intelligence.prediction.likelylose":"Kemungkinan kalah",//no i18n
"crm.intelligence.prediction.halfchance":"Peluang 50:50",//no i18n
"crm.intelligence.prediction.score":"Skor Prediksi",//no i18n
"crm.lead.prediction.recent.score":"Skor prediksi terkini",//no i18n
"crm.intelligence.prediction.lastconv":"3 Percakapan terakhir",//no i18n
"crm.intelligence.prediction.recordsfocus":"Catatan untuk fokus",//no i18n
"crm.intelligence.prediction.slowmoving":"Pergerakan Lambat",//no i18n
"crm.intelligence.prediction.trend.down":"Baru saja Tren turun",//no i18n
"crm.label.touched.records":"Catatan yang Ditangani",//no i18n
"crm.label.untouched.records":"Catatan yang belum Ditangani",//no i18n
"crm.label.record.action":"Tindakan Catatan",//no i18n
"workflow.rule.view.label.Modified":"Dimodifikasi",//no i18n
"crm.label.not.modified":"Tidak Dimodifikasi",//no i18n
"crm.label.related.records.action":"Tindakan Catatan Terkait",//no i18n
"Done":"Selesai",//no i18n
"crm.label.not.done":"Tidak Selesai",//no i18n
"sentiment.model":"Sentimen Email",//no i18n
"sentiment.criteria.count":"Jumlah",//no i18n
"sentiment.criteria.percentage":"Persentase",//no i18n
"sentiment.criteria.lastmail":"Untuk email terakhir",//no i18n
"Chats":"Obrolan",//no i18n
"Attended":"Dihadiri",//no i18n
"crm.lead.prediction.popup.text":"Ketika Kemungkinan akan dikonversi {0}, skor prediksi harus di antara {1}.",//no i18n
"crm.lead.prediction.popup.final":"Harap ubah filter dengan tepat dan coba lagi.",//no i18n
"crm.custom.field.less.than.to1":"Rentang Dari tidak boleh lebih besar dari rentang Hingga.",//no i18n
"Last\ Activity\ Date":"Tanggal Aktivitas Terakhir",//no i18n
"crm.label.vendor.name":"Nama {0}",//no i18n
"days":"hari",//no i18n
"weeks":"minggu",//no i18n
"months":"bulan",//no i18n
"crm.label.general.small.after":"setelah",//no i18n
"Last\ Week":"Minggu Lalu",//no i18n
"Last\ Month":"Bulan Lalu",//no i18n
"crm.module.name":"Nama {0}",//no i18n
"Campaign":"Kampanye",//no i18n
"Tasks":"Tugas",//no i18n
"Calls":"Panggilan",//no i18n
"Events":"Acara",//no i18n
"sentiment.criteria.wrongcriteria":"Nilai kriteria tidak boleh lebih dari {0}",//no i18n
"crm.chosen.minimum.input.text":"Silakan masukkan {0} karakter atau lebih",//no i18n
"crm.intelligence.prediction.trendup":"Tren naik",//no i18n
"crm.intelligence.prediction.trenddown":"Tren turun",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Selesai" ,//no i18n
"crm.label.success":"Berhasil" ,//no i18n
"crm.label.Failure":"Kegagalan" ,//no i18n
"Both":"Keduanya" ,//no i18n
"crm.condition.cannot.empty":"Kondisi tidak boleh kosong.",//no i18n
"crm.condition.last.30.days":"30 hari terakhir",//no i18n
"crm.condition.last.60.days":"60 hari terakhir",//no i18n
"crm.condition.last.90.days":"90 hari terakhir",//no i18n
"crm.sentiment.Positive":"Positif",//no i18n
"crm.sentiment.Negative":"Negatif",//no i18n
"sentiment.positiveandnegative":"Positif dan negatif",//no i18n
"sentiment.positiveornegative":"Positif atau negatif",//no i18n
"sentiment.positiveonly":"Hanya Positif",//no i18n
"sentiment.negativeonly":"Hanya Negatif",//no i18n
"crm.sentiment.Neutral":"Netral",//no i18n
"crm.filters.select.campaign.type":"Pilih Jenis {0}",//no i18n
"crm.filters.select.campaign.status":"Pilih Status {0}",//no i18n
"campaign.Member" : "Anggota",//no i18n
	"Service":"Layanan",//no i18n
"Activities":"Aktivitas",//no i18n
"crm.livedesk.pot.nextdays":"{0} Hari Berikutnya",//no i18n
"Today\ +\ Overdue":"Hari Ini + Jatuh Tempo",//no i18n
"crm.source.user.and.system":"Pengguna & Sistem",//no i18n
"crm.source.user.or.system":"Pengguna Atau Sistem",//no i18n
"User":"Pengguna",//no i18n
"crm.source.user.only":"Hanya oleh Pengguna",//no i18n
"crm.source.system.only":"Hanya oleh Sistem",//no i18n
"Scheduled":"Terjadwal",//no i18n
"Attended\ Dialled":"Panggilan Dijawab",//no i18n
"Unattended\ Dialled":"Panggilan Tidak Dijawab",//no i18n
"Cancelled":"Dibatalkan",//no i18n
"crm.filter.email.isblocked":"diblokir",//no i18n
"crm.filter.email.isnotblocked":"tidak diblokir",//no i18n
"condition.till.now":"Hingga Sekarang",//no i18n
"crm.recurring.no.months":"{0} bulan",//no i18n
"crm.lead.prediction.tooltip":"Kemungkinan akan dikonversi - Rentang Skor",//no i18n
"crm.website.activity":"Aktivitas Situs web",//no i18n
"crm.label.More":"Lainnya",//no i18n
"crm.label.By":"Berdasarkan",//no i18n
"crm.chosen.searching.text":"Mencari...",//no i18n
"crm.label.memberstatus.is":"dan Status Anggota adalah",//no i18n
"crm.events.duration":"Durasi",//no i18n
"crm.title.clear.name":"Hapus",//no i18n
"crm.label.status.is":"dan statusnya adalah",//no i18n
"zia.last3.help":"Percakapan termasuk panggilan, tugas, {0}, email diterima, catatan, kunjungan, komentar sosial, dukungan Permintaan dari Desk.",//no i18n
"crm.label.tag.related.to":"terkait dengan",//no i18n
"crm.alert.maximum.text.values.contains":"Anda tidak dapat memasukkan lebih dari {0} nilai untuk bidang ini.",//no i18n
//filter related keys-end

//smart filter keys
"crm.lead.prediction.convert.high":"Tinggi",//no i18n
"crm.lead.prediction.convert.medium":"Menengah",//no i18n
"crm.lead.prediction.convert.low":"Rendah",//no i18n
"crm.lead.prediction.focus.nofollowup":"Tidak ada tindak lanjut berikutnya",//no i18n
"crm.lead.prediction.likely.convert":"Kemungkinan akan dikonversi",//no i18n
"crm.lead.prediction.recent.score":"Skor prediksi terkini",//no i18n
"crm.lead.prediction.tooltip":"Kemungkinan akan dikonversi - Rentang Skor",//no i18n
"crm.lead.prediction.tooltip.convert":"KEMUNGKINAN",//no i18n
"crm.lead.prediction.tooltip.score":"SKOR",//no i18n
"Planned":"Direncanakan",//no i18n
"Planned":"Direncanakan",//no i18n
"Invited":"Diundang",//no i18n
"Sent":"Terkirim",//no i18n
"Failed":"Gagal",//no i18n
"Opened":"Dibuka",//no i18n
"Bounced":"Dipentalkan",//no i18n
"Responded":"Direspons",//no i18n
"Opted\ Out":"Dikeluarkan",//no i18n
"Clicked":"Diklik",//no i18n
"Marked.as.spam":"Ditandai sebagai Spam",//no i18n
"Member\ Status":"Status Anggota",//no i18n
"Camp\ Member\ Status":"Status Anggota Kamp",//no i18n
"Campaign\ Member":"Anggota Kampanye",//no i18n
"crm.zinvoice.dueIn":"jatuh tempo dalam",//no i18n
"crm.zinvoice.dueby":"jatuh tempo sebelum",//no i18n
"Rollbacked":"dikembalikan",//no i18n
"crm.filter.label.with.open":"Dengan {0} Terbuka",//no i18n
"crm.filter.label.with.module":"Dengan {0}",//no i18n
"crm.filter.label.activity.due":"{0} Jatuh Tempo",//no i18n
"crm.filter.label.activity.done":"{0} Selesai",//no i18n
"Date":"Tanggal",//no i18n
"crm.filter.label.notes.added":"Catatan Ditambahkan",//no i18n
"crm.label.filter.email.status":"Status Email",//no i18n
"crm.filter.label.sent":"terkirim",//no i18n
"crm.filter.label.not.sent":"tidak terkirim",//no i18n
"crm.filter.label.opened":"dibuka",//no i18n
"crm.filter.label.not.opened":"tidak dibuka",//no i18n
"crm.filter.label.received":"diterima",//no i18n
"crm.filter.label.not.received":"tidak diterima",//no i18n
"crm.filter.label.bounced":"dipentalkan",//no i18n
"crm.filter.label.opened.not.replied":"dibuka dan tidak dijawab",//no i18n
"crm.filter.label.any":"Salah satu di atas",//no i18n
"crm.zia.config.potential.amount":"Jumlah {0}",//no i18n
"Quote\ Stage":"Tahap {0}",//no i18n
"crm.module.owner":"Pemilik {0}",//no i18n
"crm.label.Potential\ Closing\ Date":"Tanggal Penutupan Potensial",//no i18n
"crm.lead.prediction.likely.convert":"Kemungkinan akan dikonversi",//no i18n
"crm.intelligence.prediction.score":"Skor Prediksi",//no i18n
"crm.lead.prediction.recent.score":"Skor prediksi terkini",//no i18n
"crm.intelligence.prediction.lastconv":"3 Percakapan terakhir",//no i18n
"crm.intelligence.prediction.recordsfocus":"Catatan untuk fokus",//no i18n
"crm.intelligence.prediction.slowmoving":"Pergerakan Lambat",//no i18n
"crm.intelligence.prediction.trend.down":"Baru saja Tren turun",//no i18n
"crm.label.touched.records":"Catatan yang Ditangani",//no i18n
"crm.label.untouched.records":"Catatan yang belum Ditangani",//no i18n
"crm.label.record.action":"Tindakan Catatan",//no i18n
"workflow.rule.view.label.Modified":"Dimodifikasi",//no i18n
"crm.label.not.modified":"Tidak Dimodifikasi",//no i18n
"crm.label.related.records.action":"Tindakan Catatan Terkait",//no i18n
"Done":"Selesai",//no i18n
"crm.label.not.done":"Tidak Selesai",//no i18n
"sentiment.model":"Sentimen Email",//no i18n
"sentiment.criteria.count":"Jumlah",//no i18n
"sentiment.criteria.percentage":"Persentase",//no i18n
"sentiment.criteria.lastmail":"Untuk email terakhir",//no i18n
"Chats":"Obrolan",//no i18n
"Attended":"Dihadiri",//no i18n
"crm.warning.select.filter":"Pilih setidaknya satu bidang untuk difilter.",//no i18n
"crm.lead.prediction.popup.text":"Ketika Kemungkinan akan dikonversi {0}, skor prediksi harus di antara {1}.",//no i18n
"crm.lead.prediction.popup.final":"Harap ubah filter dengan tepat dan coba lagi.",//no i18n
"crm.custom.field.less.than.to":"Rentang <i>Dari</i> tidak boleh lebih besar dari rentang <i>Hingga</i>.",//no i18n
"Last\ Activity\ Date":"Tanggal Aktivitas Terakhir",//no i18n
"Contact\ Name":"Nama {0}",//no i18n
"crm.lead.prediction.tooltip":"Kemungkinan akan dikonversi - Rentang Skor",//no i18n
"crm.website.activity":"Aktivitas Situs web",//no i18n
"hours":"jam",//no i18n
"days":"hari",//no i18n
"weeks":"minggu",//no i18n
"months":"bulan",//no i18n
"years":"tahun",//no i18n
"crm.condition.in.last":"yang terakhir",//no i18n
"before":"sebelum",//no i18n
"crm.label.general.small.after":"setelah",//no i18n
"between":"antara",//no i18n
"Today":"Hari ini",//no i18n
"Yesterday":"Kemarin",//no i18n
"crm.thisweek":"Minggu Ini",//no i18n
"crm.label.this.month":"Bulan Ini",//no i18n
"crm.thisyear":"Tahun Ini",//no i18n
"Last\ Week":"Minggu Lalu",//no i18n
"Last\ Month":"Bulan Lalu",//no i18n
"crm.intelligence.prediction.trendup":"Tren naik",//no i18n
"crm.intelligence.prediction.trenddown":"Tren turun",//no i18n
"crm.condition.last.30.days":"30 hari terakhir",//no i18n
"crm.condition.last.60.days":"60 hari terakhir",//no i18n
"crm.condition.last.90.days":"90 hari terakhir",//no i18n
"crm.condition.until.now":"Hingga Sekarang",//no i18n
"crm.sentiment.Positive":"Positif",//no i18n
"crm.sentiment.Negative":"Negatif",//no i18n
"sentiment.positiveandnegative":"Positif dan negatif",//no i18n
"sentiment.positiveornegative":"Positif atau negatif",//no i18n
"sentiment.positiveonly":"Hanya Positif",//no i18n
"sentiment.negativeonly":"Hanya Negatif",//no i18n
"crm.sentiment.Neutral":"Netral",//no i18n
"crm.module.name":"Nama {0}",//no i18n
"Campaign":"Kampanye",//no i18n
"crm.filters.select.campaign.type":"Pilih Jenis {0}",//no i18n
"Advertisement":"Iklan",//no i18n
"Banner\ Ads":"Iklan Spanduk",//no i18n
"Conference":"Konferensi",//no i18n
"Direct\ mail":"Surat langsung",//no i18n
"crm.taskreminder.line8":"Email",//no i18n
"Others":"Lainnya",//no i18n
"Partner":"Mitra",//no i18n
"Public\ Relations":"Hubungan Masyarakat",//no i18n
"Referral\ Program":"Program Referensi",//no i18n
"Telemarketing":"Telemarketing",//no i18n
"Trade\ Show":"Pameran Dagang",//no i18n
"Webinar":"Webinar",//no i18n
"crm.filters.select.campaign.status":"Pilih Status {0}",//no i18n
"Active":"Aktif",//no i18n
"Complete":"Selesai",//no i18n
"Inactive":"Tidak aktif",//no i18n
"Planning":"Merencanakan",//no i18n
"Activities":"aktivitas",//no i18n
"Tasks":"Tugas",//no i18n
"Calls":"Panggilan",//no i18n
"Tomorrow":"Besok",//no i18n
"crm.livedesk.pot.nextdays":"{0} Hari Berikutnya",//no i18n
"Today\ +\ Overdue":"Hari Ini + Jatuh Tempo",//no i18n
"crm.source.user.and.system":"Pengguna & Sistem",//no i18n
"crm.source.user.or.system":"Pengguna Atau Sistem",//no i18n
"User":"Pengguna",//no i18n
"crm.source.user.only":"Hanya oleh Pengguna",//no i18n
"crm.source.system.only":"Hanya oleh Sistem",//no i18n
"Scheduled":"Terjadwal",//no i18n
"Attended\ Dialled":"Panggilan Dijawab",//no i18n
"Unattended\ Dialled":"Panggilan Tidak Dijawab",//no i18n
"Cancelled":"Dibatalkan",//no i18n
"crm.filter.email.isblocked":"diblokir",//no i18n
"crm.filter.email.isnotblocked":"tidak diblokir",//no i18n
"crm.condition.till.today":"Hingga Hari Ini",//no i18n
"crm.recurring.no.months":"{0} bulan",//no i18n
"sentiment.criteria.wrongcriteria":"Nilai kriteria tidak boleh lebih dari {0}",//no i18n
"crm.chosen.minimum.input.text":"Silakan masukkan {0} karakter atau lebih",//no i18n
"crm.chosen.searching.text":"Mencari...",//no i18n
"is":"adalah",//no i18n
"crm.label.memberstatus.is":"dan Status Anggota adalah",//no i18n
"crm.events.duration":"Durasi",//no i18n
"and":"dan",//no i18n
"crm.title.clear.name":"Hapus",//no i18n
"zia.last3.help":"Percakapan termasuk panggilan, tugas, {0}, email diterima, catatan, kunjungan, komentar sosial, dukungan Permintaan dari Desk.",//no i18n
"crm.label.By":"Berdasarkan",//no i18n
"crm.label.add.note":"Tambahkan Catatan",//no i18n
"crm.label.simply.by":"oleh",//no i18n
"crm.general.addnote":"Tambahkan catatan",//no i18n
"crm.general.addtitle":"Tambahkan judul",//no i18n
"crm.label.attach.file":"Lampirkan File",//no i18n
"crm.button.mass.delete":"Hapus",//no i18n
"crm.warning.delete.record":"Anda yakin ingin menghapus \"{0}\"?",//no i18n
"crm.label.yes":"Ya",//no i18n
"crm.note.view.previous":"Lihat Catatan Sebelumnya",//no i18n
"of":"dari",//no i18n
"crm.label.notes":"Catatan",//no i18n
"crm.note.recent.first":"Terbaru Pertama",//no i18n
"crm.note.recent.last":"Terbaru Terakhir",//no i18n
"crm.territory.label.only":"Hanya {0}",//no i18n
"crm.button.apply.filter":"Terapkan Filter",//no i18n
"PM":"Malam",//no i18n
"crm.mb.newversion.msg4":"OK, mengerti!",//no i18n
"Jan":"Jan",//no i18n
"Feb":"Feb",//no i18n
"Mar":"Mar",//no i18n
"Apr":"Apr",//no i18n
"Jun":"Jun",//no i18n
"Jul":"Jul",//no i18n
"Aug":"Agu",//no i18n
"Sep":"Sep",//no i18n
"Oct":"Okt",//no i18n
"Nov":"Nov",//no i18n
"Dec":"Des",//no i18n
"crm.label.unmapped.stages":"Tidak Dihitung",//no i18n
"crm.wf.summary.label.ShowInstantActions":"Tampilkan Lebih Banyak",//no i18n
"crm.wf.summary.label.HideInstantActions":"Tampilkan Lebih Sedikit",//no i18n
"crm.label.account.created":"{0} yang baru akan dibuat.",//no i18n
"crm.krp.no.records.found":"Tidak ada {0} yang ditemukan",//no i18n
"crm.module.new":"{0} Baru",//no i18n
"crm.label.view":"Lihat",//no i18n
"crm.nsocial.customers":"Pelanggan",//no i18n
"crm.nsocial.open.potential":"Buka {0}",//no i18n
"crm.nsocial.lead.contact":"{0}/{1}",//no i18n
"Others":"Lainnya",//no i18n
"crm.field.length.check":"Nilai {0} melebihi panjang maksimum.",//no i18n
"crm.lower.now":"sekarang",//no i18n
"crm.time.min.ago":"{0} mnt yang lalu",//no i18n
"crm.time.mins.ago":"{0} mnt yang lalu",//no i18n
"crm.time.hr.ago":"{0} jam yang lalu",//no i18n
"crm.time.hrs.ago":"{0} jam yang lalu",//no i18n
"AllUsers":"Semua Pengguna",//no i18n
"crm.label.search":"Cari",//no i18n
"crm.api.filterby":"Filter berdasarkan",//no i18n
"crm.customview.nofields.found":"--Tidak Ada Bidang yang Cocok--",//no i18n
"crm.setup.system.ziarecommendation":"Rekomendasi",//no i18n
"crm.filter.label.all.products":"Semua {0}",//no i18n
"crm.filter.label.select.products":"{0} yang Dipilih",//no i18n
"crm.reviewprocess.smart.filter":"Status Proses Peninjauan Catatan",//no i18n
"crm.dashboard.sharing.empty.value.alert":"Harap pilih nilai.",//no i18n
"crm.segmentation.segment.score":"Skor Segmen",//no i18n
"crm.filter.label.in":"berdasarkan",//no i18n
"crm.filter.label.and.purchase.in":"dan Mungkin Membeli di",//no i18n
"crm.filter.label.last.purchased":"dan baru-baru ini memperoleh",//no i18n
"crm.filter.label.a.day":"Sehari",//no i18n
"crm.filter.label.a.week":"Seminggu",//no i18n
"crm.filter.label.a.month":"Sebulan",//no i18n
"crm.cal.custom":"Kustom",//no i18n
"crm.mb.field.common.empt":"Nilai tidak boleh kosong.",//no i18n
"crm.chosen.error.loading.text":"Ups, kami tidak dapat memuat hasil Anda",//no i18n
"crm.filter.label.firstbuy":"Pertama kali",//no i18n
"crm.filter.label.cwbab":"Dependen",//no i18n
"crm.filter.label.fbt":"Bundel",//no i18n
"crm.filter.label.rebuy":"Ulangi",//no i18n
"crm.filter.label.nextbuy":"Urutan",//no i18n
"crm.mxnlookup.select":"Tetapkan {0}",//no i18n
"crm.lookup.chooserecord":"Pilih {0}",//no i18n
"crm.record.selected":"{0} yang Dipilih",//no i18n
"crm.module.empty.message":"Tidak ada {0} yang ditemukan",//no i18n
"crm.mxnlookup.selected":"{0} yang Ditetapkan",//no i18n
"crm.security.error":"Anda tidak memiliki izin yang memadai untuk melakukan operasi ini. Hubungi administrator Anda.",//no i18n
"crm.label.creator.noPermission":"Izin Ditolak",//no i18n
"crm.segmentation.recency":"Kebaruan",//no i18n
"crm.segmentation.frequency":"Frekuensi",//no i18n
"crm.segmentation.monetary":"Keuangan",//no i18n
"crm.smartfilter.related.module.msg":"Anda tidak dapat memilih lebih dari tiga modul terkait.",//no i18n
"crm.smartfilter.related.module.msg1":"(Misal: Email, Aktivitas, Catatan)",//no i18n
"crm.smartfilter.related.module.msg2":"Durasi tidak boleh kosong", //no i18n
"crm.label.timeZone": "Zona Waktu", //NO I18n
"crm.label.insufficient.privileges": "Hak Istimewa Tidak Memadai untuk melakukan operasi ini. Hubungi Administrator Anda.", //NO I18n
"crm.filter.header.secton.system": "Filter yang Ditentukan Sistem", //NO I18N
"crm.filter.header.secton.fields": "Filter Berdasarkan Bidang", //NO I18N
"crm.createfield.calcinfo.new" : "Bidang ini berfungsi sebagai penghitung untuk setiap ekspresi yang Anda masukkan.</br> <b>Mis. 20+20</b> akan menghasilkan <b>40</b> secara otomatis",//No i18n
"crm.lable.read.only" : "Bidang hanya baca",//No i18n
"crm.column.sort.asc" : "Menaik",//No I18n
"crm.column.sort.desc" : "Desk",//No i18n
	"crm.column.unsort" : "Batalkan penyortiran",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Bagikan ke pelanggan", //NO I18N
"crm.label.edited": "Edited", //NO I18N
"crm.label.edited.on": "Edited on", //NO I18N
"crm.message.limit.exceed": "Hanya {0} karakter yang diizinkan untuk {1}.", //NO I18N
"custmr.prtl.notes.shrd.with.custmr": "Dibagikan dengan Pelanggan", //NO I18N
"crm.button.ok" : "OK", //NO I18N
"crm.role.already.selected" : "Peran ini telah dipilih", //no i18n
"crm.user.deleted": "PENGGUNA TELAH DIHAPUS",  //NO I18N
"crm.account.closed": "AKUN INI TELAH DITUTUP",  //NO I18N
"crm.start.chat": "Mulai obrolan",  //NO I18N
"crm.start.call": "Mulai panggilan",  //NO I18N
"crm.recipient.invalid.email" : "Ditemukan email tidak valid.", //NO I18N
"crm.recipient.add.recipient" : "Tambahkan Penerima Tambahan", //NO I18N
"crm.start.video.call": "Mulai panggilan video", //NO I18N //ignorei18n_start

"Score":"Skor",
"Positive Score":"Skor Positif",
"Negative Score":"Skor Negatif",
"Touch Point Score":"Skor Titik Kontak",
"Positive Touch Point Score":"Skor Titik Kontak Positif",
"Negative Touch Point Score":"Skor Titik Kontak Negatif",
"crm.label.scoring.rules":"Aturan Pemberian Nilai",
"crm.label.type.minutes": "Ketik di sini dalam menit", //NO I18N

"is\ OPEN":"TERBUKA",//no i18n
"is\ WON":"DIMENANGKAN",//no i18n
"is\ LOST":"KALAH",//no i18n
"crm.potential.all.open":"Semua tahap Terbuka",//no i18n
"crm.potential.all.won":"Semua tahap yang Dimenangkan Tertutup",//no i18n

"crm.potential.all.lost":"Semua tahap yang KalahTertutup",//no i18n
"crm.campaign.member.status" : "Status Anggota",//no i18n
"crm.dashboard.select.type" : "Pilih {0}",//no i18n
"crm.campaign.service.status":"Status Layanan",//no i18n

"crm.label.addColumn":"Tambahkan Kolom",//no i18n
"crm.button.clear.filter":"Tutup Filter",//no i18n
"crm.button.show.filter":"Tampilkan Filter",//no i18n
"crm.las.error.user.maxlimit":"Anda hanya dapat memilih maksimum 20 pengguna.",//no i18n
"crm.las.error.picklist.maxlimit":"Anda hanya dapat memilih maksimum 20 opsi.",//no i18n

"crm.fileuploader.message.responseerror": "Unggah gagal", //NO I18N
"crm.storage.create.error": "Tidak ada catatan baru yang dapat dibuat karena Anda telah mencapai batas penyimpanan data maksimum Anda.", //NO I18N
"crm.storage.create.error.client": "Tidak ada catatan baru yang dapat dibuat karena admin Anda telah mencapai batas penyimpanan maksimumnya. Hubungi {0} untuk menyelesaikan masalah ini.", //NO I18N
"crm.storage.avail.info": "({0} tersisa dari {1})", //NO I18N
"crm.storage.error.key.manage": "Kelola penyimpanan data Anda", //NO I18N
"Records": "Catatan" ,//NO I18N
"crm.workflow.alert.additional.recipients" : "Penerima Tambahan", //NO I18N
"crm.workflow.alert.type.otherEmails" : "Gunakan koma untuk memisahkan alamat email tambahan.", //NO I18N
"crm.related.contact.account" : "{0} terkait dengan {1}",//No I18n
	"crm.allcontact.show" : "Semua {0}",//No I18n
	"crm.button.mass.show" : "Tampilkan",//No I18n
"crm.start.video.call": "Mulai panggilan video",  //NO I18N
"crm.msg.custom.view.not.replied" : "Pesan Tidak Dibalas", //NO I18N
"crm.msg.custom.view.replied" : "Pesan Dibalas",//NO I18N
"crm.workflow.select.recipients" : "Penerima", //NO I18N
"crm.custom.module.no.profile.selected.alert1":"Harap pilih minimal satu profil.",//NO I18N
"crm.auto.enrich.remove.default.profile" : "Maaf, Anda tidak dapat menghapus profil default.",//NO I18N
"crm.inv.label.add.emails" : "Tambahkan Email", //NO I18N
"crm.prediction.analytics.filter.year":"Tahun Lalu",//no i18n
"Previous\ FY":"TA Sebelumnya",//no i18n
"Current\ FY":"TA Saat Ini",//no i18n
"Next\ FY":"TA Berikutnya",//no i18n
"Current\ FQ":"KA Saat Ini",//no i18n
"Next\ FQ":"KA Berikutnya",//no i18n
"Previous\ FQ":"FQ Sebelumnya",//no i18n
	"crm.picklist.sample.text":"Teks Sampel",//no i18n
	"crm.more.colors":"Lebih Banyak Warna",//no i18n
	"crm.button.back.alone":"Kembali",//no i18n
	"crm.field.label.email":"Email",//no i18n
"crm.zia.nba.feature.label":"Tindakan terbaik berikutnya",//no i18n
"Meeting":"Pertemuan",//no i18n
"crm.gdpr.notavailable.field":"Tidak Tersedia",//no i18n
	"crm.setup.system.ziasimilarity":"Rekomendasi kemiripan",//no i18n
	"crm.gdpr.notavailable.field":"Tidak Tersedia",//no i18n
	"crm.filter.label.all.products":"Semua {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics",// NO I18N
	"crux.custom.field.greater.than.equalto":"{0} harus lebih besar dari atau sama dengan {1}.",
	"crux.users.selected.plural" : "{0} Pengguna Dipilih.",
	"crux.user.selected.singular" :"{0} Pengguna Dipilih.",
	"crux.criteria.empty.secondaryfield.module" : "Tidak ditemukan bidang yang {0} cocok di {1} modul",
	"crux.criteria.empty.secondaryfield" : "Bidang {0} yang lain tidak tersedia untuk perbandingan, harap masukkan nilai untuk dibandingkan.",
	"crux.logged.in.role.definition" : "Peran pengguna yang memulai tindakan catatan",
	"crux.max.limit.unselect" : "Anda tidak dapat membatalkan pilihan lebih dari {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d telah dipilih" //NO I18N
}
